import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

// Externals
import { Box, Button, Dialog, IconButton, Link, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Formik } from 'formik';
import { t } from '@lingui/macro';

// Components
import Scrollbar from 'components/legacy/components/Scrollbar';

// Steps
import StepSignIn from '../../steps/sign-in';
import ResetPasswordStep from '../../steps/reset-password';

type Steps = 'sign-in' | 'reset-password';

const FormSignIn: FC<any> = (props) => {
  const params = new URLSearchParams(window.location.search); // id=123
  let initialStep = params.get('initialStep_signin'); // 123
  const isOpen = params.get('open');

  const itemsRef = useRef([]);

  const [open, setOpen] = useState<boolean>(initialStep ? true : false);
  const [step, setStep] = useState<Steps>((initialStep as Steps) || 'sign-in');

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, 6);
  }, []);

  return (
    <>
      {props.status === 'loading' ? (
        ''
      ) : (
        <Button
          id="header_signin"
          onClick={() => {
            setStep('sign-in');
            setOpen(true);
          }}
          color="primary"
          size="medium"
          type="submit"
          variant="outlined"
          sx={{ my: 3 }}
        >
          {t`ME CONNECTER`}
        </Button>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: params.get('email') ? params.get('email') : '',
          url: params.get('url') ? params.get('url') : '',
          password: ''
        }}
        onSubmit={() => {}}
        validateOnMount={true}
      >
        {({ handleBlur, handleChange, handleReset, handleSubmit, values, resetForm }): JSX.Element => (
          <form noValidate={true} onReset={handleReset} onSubmit={handleSubmit}>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              onClose={() => {
                setTimeout(() => {
                  resetForm({
                    values: {
                      email: '',
                      url: '',
                      password: ''
                    }
                  });
                }, 150);

                setOpen(false);
                setStep('sign-in');
              }}
              open={open}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  p: 2
                }}
              >
                <Box sx={{ height: 40, width: 40 }} />
                <Typography align="center" sx={{ color: '#99A4AE' }} variant="overline">
                  {step === 'sign-in' ? t`Connexion` : t`Onboarding`}
                </Typography>
                <IconButton
                  onClick={() => {
                    setTimeout(() => {
                      resetForm({
                        values: {
                          email: '',
                          url: '',
                          password: ''
                        }
                      });
                    }, 150);

                    setOpen(false);
                    setStep('sign-in');
                  }}
                >
                  <Close color="primary" />
                </IconButton>
              </Box>
              {step === 'sign-in' && (
                <StepSignIn handleBlur={handleBlur} handleChange={handleChange} values={values} setStep={setStep} />
              )}
              {step === 'reset-password' && (
                <Scrollbar options={{ suppressScrollX: true }}>
                  <ResetPasswordStep
                    initialEmail={values.email}
                    onFinished={() => {
                      setStep('sign-in');
                    }}
                    onGoBack={() => {
                      setStep('sign-in');
                    }}
                  />
                </Scrollbar>
              )}
            </Dialog>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormSignIn;
